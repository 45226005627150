<script setup lang="ts">
import {setProductSchemaRating} from "~/utils/setSchema.js";
const router = useRouter()

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})

router.beforeResolve(() => {
  document.querySelector('body').style.overflow = ''
})

useRouteCache((helper) => {
  helper.setMaxAge(3600).setCacheable()
})

setProductSchemaRating()

</script>

<template>
  <div>
    <Html
      :lang="head.htmlAttrs.lang"
      :dir="head.htmlAttrs.dir"
    >
      <Head>
        <template
          v-for="link in head.link"
          :key="link.id"
        >
          <Link
            :id="link.id"
            :rel="link.rel"
            :href="link.href"
            :hreflang="link.hreflang"
          />
        </template>
        <template
          v-for="meta in head.meta"
          :key="meta.id"
        >
          <Meta
            :id="meta.id"
            :property="meta.property"
            :content="meta.content"
          />
        </template>
      </Head>
      <Body>
        <LazyNuxtLoadingIndicator />
        <HeaderTopBanner />
        <Header />
        <slot />
        <LazyFooter />
      </Body>
    </Html>
  </div>
</template>

<!-- TODO: rework this part after production release with the new header -->
<style scoped lang="scss">
html, body {
  .main-header {
    transition: top .2s ease-in-out;
    position: sticky;
    top: -93px;
    background: white;
    z-index: 10;

    &.visible {
      top: 0;
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {
      position: static;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, lg)) {
  html, body {
    &.sticky {
      .main-header {
        position: sticky;
        top: 0;
      }
    }
  }
}
</style>
