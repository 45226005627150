<script setup lang="ts">
const updateFocus = () => {
  const el = document.getElementById('newsletter-email-footer-newsletter')
  const rect = el.getBoundingClientRect()

  window.scrollTo(0, rect.top - (92 + 108))
  el.focus({ focusVisible: true })
}
</script>

<template>
  <div class="container-top-banner gutter-xs">
    <div class="container-scroll">
      <div class="container-banner">
        <ReviewStars />
        <NuxtLink
          to="#"
          @click.prevent="updateFocus()"
        >
          <span v-html="$t('top_banner.description')"></span>
        </NuxtLink>
        <span> <img src="/images/lang/fr.svg" :alt="$t('top_banner.right_text')" /> {{ $t('top_banner.right_text') }}</span>
      </div>
      <div class="container-banner">
        <ReviewStars />
        <NuxtLink
          to="#"
          @click.prevent="updateFocus()"
        >
          <span v-html="$t('top_banner.description')"></span>
        </NuxtLink>
        <span><img src="/images/lang/fr.svg" :alt="$t('top_banner.right_text')" /> {{ $t('top_banner.right_text') }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.container-top-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  background: var(--color-navy-450);
  color: var(--color-primary-white);

  a {
    color: white;
  }

  .container-scroll {
    display: flex;
    gap: 9em;
    white-space: nowrap;
    animation: bannerAnimation linear 25s infinite;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      animation: none;
      gap: 0;
      flex: 1;
    }

    @keyframes bannerAnimation {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(calc(-50% - 1em));
      }
    }
  }

  .container-banner {
    color: var(--color-primary-white);
    display: flex;
    align-items: center;
    gap: 4em;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      flex: 1;
      justify-content: space-around;
    }

    &:first-of-type {
      @media (min-width: map-get($grid-breakpoints, lg)) {
        display: none;
      }
    }

    span {
      display: flex;
    }

    img {
      width: 15px;
      margin-right: 5px;
    }
  }
}
</style>
